import React, { useEffect, useState } from 'react';
// import slugify from 'slugify';
import { getTreeFromFlatData } from 'react-sortable-tree';
import JoditEditor from 'jodit-react';
import {
  Tabs,
  Divider,
  Input,
  Checkbox,
  Select /* DatePicker, */,
  // Button,
  Form,
  Row,
  Col,
  TreeSelect,
} from 'antd';
import dayjs from 'dayjs';
// import moment from 'moment';
import useAxios from '../../hooks/useAxios';
import UploadBox from '../base/UploadBox';
import DNDGallery from '../base/DNDGallery';
import { GalleryModal } from './';

// const { Option } = Select;

/**
 * @constant {Integer} GALLERY_LIMIT - Max number of images in gallery
 */
const GALLERY_LIMIT = 20;

const configJodit = {
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
  toolbarAdaptive: false,
  minHeight: '300',
  buttons: [
    'source',
    '|',
    'bold',
    'strikethrough',
    'underline',
    'italic',
    'eraser',
    '|',
    'superscript',
    'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    // 'font',
    'fontsize',
    '\n',
    'brush',
    'paragraph',
    'link',
    'align',
    // '|',
    'undo',
    'redo',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
    'fullsize',
    'print',
    'preview',
    'find',
  ],
};

const formInit = {
  _id: null,
  title: {},
  visible: false,
  // googleDescription: {},
  // keywords: {},
  content: {},
  // url: '',
  code: '',
  // price: 0,
  // discount: false,
  // discountPrice: 0,
  // fullUrl: '',
  // featuredAd: false,
  // featuredAdUntil: '',
  status: 'ACTIVE',
  category: '',
  categoryPath: '',
  attributes: [],
  featureImage: undefined,
  gallery: [],
  declaration: undefined,
  importPrice: 0,
  wholesalePrice: 0,
  quantity: 0,
  // retailPrice: 0,
  // discountWholesalePrice: 0,
  // discountRetailPrice: 0,
  inStock: true,
};

const getCategoryPath = (categoryId, categories) => {
  const category = categories.find((cat) => cat._id === categoryId);
  return category ? category.path : '';
};

const DataFrom = ({ isNew, data, categories, language, onSubmit, SERVER_URL, token, declarations }) => {
  const [form] = Form.useForm();
  const [attributes, fetchAttributes] = useAxios('', [], token, 'get');
  const [globalAttributes, fetchGlobalAttributes] = useAxios('', [], token, 'get');
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  // const [view, setView] = useState('wholesale');
  // const [checkedDiscountWholesale, setCheckedDiscountWholesale] = useState();
  // const [checkedDiscountRetail, setCheckedDiscountRetail] = useState();

  if (data) ['children', 'createdAt', 'path', 'updatedAt', '__v'].forEach((key) => delete data[key]);

  let initialValues = { ...formInit, ...data };
  // initialValues.url = data && data.url?.sr;
  const [image, setImage] = useState(initialValues.featureImage ? initialValues.featureImage.url : undefined);
  const [currentGallery, setCurrentGallery] = useState(initialValues.gallery?.length > 0 ? initialValues.gallery : []);

  useEffect(() => {
    fetchGlobalAttributes(`${SERVER_URL}/attributes?filter={"isGlobal":true}`, []);
    if (data) {
      if (data.featuredAdUntil) form.setFieldsValue({ featuredAdUntil: dayjs(data.featuredAdUntil) });
      if (data.category) {
        const categoryPath = getCategoryPath(data.category, categories);
        fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
      }
      if (data.attributes && data.attributes.length > 0) {
        data.attributes.forEach((d) => {
          if (d && d.dateValue) {
            d.dateValue = dayjs(d.dateValue);
          }
        });
      }
    }
  }, [data, categories, form, SERVER_URL, fetchAttributes, fetchGlobalAttributes]);

  useEffect(() => {
    if (globalAttributes.data.items && attributes.data) {
      setCategoryAttributes([...globalAttributes.data.items, ...attributes.data]);
    }
  }, [attributes, globalAttributes]);

  const treeData = getTreeFromFlatData({
    flatData: categories.map((node) => ({
      title: node.name?.sr,
      value: node._id,
      key: node._id,
      parent: node.parent,
      _id: node._id,
    })),
    getKey: (node) => node._id, // resolve a node's key
    getParentKey: (node) => node.parent, // resolve a node's parent's key
    rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
  });

  const onChangeAttribute = (index, attrId, name, value) => {
    let prevAttributes = form.getFieldValue('attributes');
    const attr = {
      attributeId: attrId,
      [name]: name === 'values' ? (!Array.isArray(value) ? [value] : value) : value,
    };
    prevAttributes[index] = attr;
    form.setFieldsValue({ attributes: prevAttributes });
  };

  const onFinish = async (values, isNew) => {
    if (!isNew) {
      values._id = data._id;
    }
    onSubmit(values, isNew);
  };

  /**
   * Open feature image modal
   */
  const editImageTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'featureImage' });
  };

  /**
   *  Delete feature Image
   */
  const deleteFeatureImageHandler = () => {
    setImage(null);
    form.setFieldsValue({ featureImage: null });
  };

  /**
   * Open gallery modal
   */
  const editGalleryTrigger = () => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: GALLERY_LIMIT });
  };

  /**
   * Open gallery modal single image edit
   */
  const editGallerySingleImageTrigger = (index, id) => {
    setModal({ ...modal, visible: true, formKey: 'gallery', limit: 1, index, id });
  };

  /**
   * Delete image in gallery by id
   * @param {String} id
   */
  const deleteGalleryImageHandler = (id) => {
    const newGallery = currentGallery.filter((image) => image._id !== id);
    setCurrentGallery(newGallery);
    form.setFieldsValue({ gallery: newGallery });
  };

  /**
   * Insert image/images in form by form key
   * @param {[Object]} values
   * @param {String} formKey
   */
  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    if (modal.formKey === 'gallery') {
      setCurrentGallery(values);
    }
    if (modal.formKey === 'featureImage') {
      setImage(values);
    }
  };

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form
          className='form-horizontal'
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                  {/* {language.list.map((lang) => ( */}
                  <Tabs.TabPane key={language.default.code} forceRender>
                    <Form.Item label='Vidljiv na sajtu' name='visible' valuePropName='checked'>
                      <Checkbox />
                    </Form.Item>
                    <Form.Item
                      label='Naziv proizvoda'
                      rules={[
                        {
                          required: language.default.code === language.default.code ? true : false,
                          message: 'Molimo Vas da unesete naziv proizvoda!',
                        },
                      ]}
                      name={['title', language.default.code]}
                      // onChange={(e) => form.setFieldsValue({ url: slugify(e.target.value, { lower: true, strict: true }) })}
                    >
                      <Input
                        onChange={(event) =>
                          form.setFieldsValue({ url: event.target.value.replace(/ /g, '-').toLowerCase() })
                        }
                      />
                    </Form.Item>

                    <Form.Item label='Šifra proizvoda' name='code'>
                      <Input />
                    </Form.Item>

                    <Form.Item label='Opis naziva proizvoda' name={['headerContent', language.default.code]}>
                      <JoditEditor
                        name={`headerContent[${language.default.code}]`}
                        style={{ margin: '2px 0px', height: '550px !important' }}
                        config={configJodit}
                      />
                    </Form.Item>

                    <Form.Item label='Opis proizvoda' name={['content', language.default.code]}>
                      <JoditEditor
                        name={`content[${language.default.code}]`}
                        style={{ margin: '2px 0px', height: '550px !important' }}
                        config={configJodit}
                      />
                    </Form.Item>

                    <Form.Item label='Seo title' name={['metaTitle', language.default.code]}>
                      <Input />
                    </Form.Item>

                    <Form.Item label='Seo opis' name={['metaDesc', language.default.code]}>
                      <Input />
                    </Form.Item>

                    <Form.Item label='Seo Ključne reči' name={['metaKeywords', language.default.code]}>
                      <Input />
                    </Form.Item>
                  </Tabs.TabPane>
                  {/* ))} */}
                </Tabs>

                <Divider type='horizontal' />

                <Row type='flex' gutter={4} justify='start' align='top'>
                  <Col xs={24} md={12}>
                    <div>
                      <Form.Item
                        label='Status'
                        name='status'
                        rules={[{ required: true, message: 'Please select status!' }]}
                      >
                        <Select>
                          {['ACTIVE', 'SUSPENDED'].map((option, index) => (
                            <Select.Option key={`${option}_${index}`} value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item label='Uvozna cena bez PDV' name='importPrice'>
                        <Input type='number' />
                      </Form.Item>
                      <Form.Item label='Veleprodajna cena bez PDV' name='wholesalePrice'>
                        <Input type='number' />
                      </Form.Item>
                      <Form.Item label='Količina' name='quantity'>
                        <Input type='number' />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                {/* <Form.Item label='Na zalihama' name='inStock' valuePropName='checked'>
                  <Checkbox />
                </Form.Item> */}
              </div>
            </Col>

            <Col xs={24} md={8}>
              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Kategorija</h4>
                </div>
                <br />
                <Form.Item
                  label='Kategorija'
                  name='category'
                  className='panel-body'
                  rules={[{ required: false, message: 'Molim Vas da odaberete kategoriju!' }]}
                >
                  <TreeSelect
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownAlign={{ offset: [0, -80] }}
                    style={{ width: '100%', height: 30 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    name='category'
                    treeData={treeData}
                    placeholder='Kategorija'
                    onChange={(value) => {
                      form.setFieldsValue({ attributes: [] });
                      const categoryPath = getCategoryPath(value, categories);
                      fetchAttributes(`${SERVER_URL}/attributes/category/${categoryPath}`, []);
                    }}
                  />
                </Form.Item>
              </div>

              {/* <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Atributi</h4>
                </div>

                <div className='panel-body'>
                  <Form.List name='attributes'>
                    {(fields) =>
                      categoryAttributes.length > 0 &&
                      categoryAttributes.map((attr, ind) => {
                        switch (attr.type) {
                          case 'MULTICHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'values']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select
                                  mode='multiple'
                                  onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}
                                >
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value[language.selected.code]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'CHOICE':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'values', 0]}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Select onChange={(value) => onChangeAttribute(ind, attr._id, 'values', value)}>
                                  {attr.values.map((val) => (
                                    <Select.Option value={val._id} key={val._id}>
                                      {val.value[language.selected.code]}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            );

                          case 'INPUT':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'inputValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'inputValue', e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'NUMBER':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'numberValue']}
                                // className='panel-body'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please enter ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Input
                                  type='number'
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'numberValue', +e.target.value)}
                                />
                              </Form.Item>
                            );

                          case 'CHECKBOX':
                            return (
                              <Form.Item
                                key={attr._id}
                                label={attr.name[language.selected.code]}
                                name={[ind, 'checkboxValue']}
                                // className='panel-body'
                                valuePropName='checked'
                                rules={[
                                  {
                                    required: attr.isRequired,
                                    message: `Please select ${attr.name[language.selected.code]}`,
                                  },
                                ]}
                                {...fields}
                              >
                                <Checkbox
                                  onChange={(e) => onChangeAttribute(ind, attr._id, 'checkboxValue', e.target.checked)}
                                />
                              </Form.Item>
                            );

                          default:
                            return null;
                        }
                      })
                    }
                  </Form.List>
                </div>
              </div> */}

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Istaknuta slika</h4>
                </div>

                <Form.Item name='featureImage' valuePropName='image'>
                  <div className='panel-body'>
                    <br />
                    <UploadBox
                      editHandler={editImageTrigger}
                      deleteHandler={deleteFeatureImageHandler}
                      image={image}
                      index={0}
                      name='featureImage'
                    />
                  </div>
                </Form.Item>
              </div>

              <div className='panel panel-primary'>
                <div className='panel-heading'>
                  <h4 className='panel-title'>Galerija slika</h4>
                </div>

                <Form.Item name='gallery' valuePropName='image'>
                  <div className='panel-body'>
                    <br />
                    {currentGallery?.length > 0 && (
                      <DNDGallery
                        deleteGalleryImageHandler={deleteGalleryImageHandler}
                        editGallerySingleImageTrigger={editGallerySingleImageTrigger}
                        form={form}
                        setGallery={setCurrentGallery}
                      />
                    )}
                    <UploadBox editHandler={editGalleryTrigger} />
                    {/* {currentGallery?.length < GALLERY_LIMIT && <UploadBox editHandler={editGalleryTrigger} />} */}
                  </div>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <div className='text-right'>
            <button className='b2b-primary'>{isNew ? 'Dodaj' : 'Izmeni'} proizvod</button>
          </div>
        </Form>

        {modal.visible && (
          <GalleryModal
            visible={modal.visible}
            limit={modal.limit}
            formKey={modal.formKey}
            imageSavePath='public/images/data/'
            imageType='data'
            imageHeight={1000}
            SERVER_URL={SERVER_URL}
            token={token}
            form={form}
            onCancel={() => setModal({ visible: false, formKey: null, limit: 1 })}
            onInsert={(values) => onInsert(values, modal.formKey)}
            imageId={modal.id}
            imageIndex={modal.index}
          />
        )}
      </div>
    </div>
  );
};

export default DataFrom;
